import palette from '../../styles/palette';

const styles = ({ typography }) => ({
  container: {
    minHeight: '400px',
    display: 'grid',
    gap:".5rem",
    gridTemplateColumns:
      'minmax(0, 1fr) minmax(auto, 120ch) minmax(0, 1fr)',
    position: 'relative',
  },
  paragraph: {
    gridColumn: 2,
    overflow: 'auto'
  },
  /* position images to take up all three columns */
  'article-picture': {
    gridColumn: '1 / 4',
    width: '100%',
    maxWidth: '120ch',
    justifySelf: 'center',
    marginTop:'1rem',
  },
  title: {
    textDecoration: 'none',
    gridColumn: 2,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0',
  },
  'blog-list-title': {
    textDecoration: 'none',
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: '16px',
    '&::after': {
      background: 'transparent',
      bottom: 0,
      content: "''",
      cursor: 'pointer',
      display: 'block',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 10,
    },
    '&:hover:after': {
      outline: '2px solid var(--primary)',
      outlineOffset: '.2rem',
    },
  },
  ttr: {
    display: 'flex',
    alignItems: 'center',
  },
  clock: {
    marginRight: '10px',
  },
});

export default styles;
