import { Link } from 'gatsby';
import React from 'react';
import { withStyles } from '@material-ui/styles';

import { Avatar, Navigation } from 'components';
import { MdLightbulbOutline, MdWbIncandescent } from 'react-icons/md';

import styles from './style';
import { useSettings } from '../../context/settings';
import withWidth from '@material-ui/core/withWidth';

import { compose, isMobileView } from 'utils';

const Header = ({ classes, width }) => {
  const { darkMode, toggleDarkMode } = useSettings();
  return (
    <header className={classes.container}>
      <Link to='/' className={classes.logo}>
        <Avatar />
      </Link>

      <div className={classes.rightSide}>
        <div className={classes.navigation}>
          <Navigation />
        </div>
        {!isMobileView(width) && (darkMode ? (
          <MdLightbulbOutline
            onClick={toggleDarkMode}
            className={classes.switch}
          />
        ) : (
          <MdWbIncandescent
            onClick={toggleDarkMode}
            className={classes.switch}
          />
        ))}
      </div>
    </header>
  );
};

export default compose(withWidth(), withStyles(styles))(Header);
