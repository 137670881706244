import React from 'react';
import Helmet from 'react-helmet';
import useSiteMetadata from './useSiteMetadata';

function SEO({ description, lang, meta, title }: Props) {
  const siteMetadata = useSiteMetadata();

  const metaDescription = description || siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: '@skendza',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        }
      ].concat(meta)}>
      <link
        href='https://fonts.googleapis.com/css?family=Roboto:300,00,500,700,900&subset=latin-ext'
        rel='stylesheet'
      />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `sr`,
  meta: [],
  description: ``,
};

export default SEO;
