import React from 'react';
import Link from 'gatsby-link';
import { withStyles } from '@material-ui/styles';
import { FaBars } from 'react-icons/fa';

import styles from './style';
import { MdLightbulbOutline, MdWbIncandescent } from 'react-icons/md';
import { useSettings } from '../../../context/settings';

const MobileNavigation = ({ classes, options, onClick, open }) => {
  const { darkMode, toggleDarkMode } = useSettings();
  return (
    <>
      <FaBars className={classes.menuIcon} onClick={onClick} />
      {open && (
        <div className={classes.menuContainer}>
          {options.map((link, i) => (
            <div key={i}>
              <Link
                to={link.to}
                className={classes.navLink}
                activeClassName={classes.navLinkActive}
                exact={'true'}>
                <span>{link.text}</span>
              </Link>
            </div>
          ))}
          {darkMode ? (
            <MdLightbulbOutline
              onClick={toggleDarkMode}
              className={classes.switch}
            />
          ) : (
            <MdWbIncandescent
              onClick={toggleDarkMode}
              className={classes.switch}
            />
          )}
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(MobileNavigation);
