import React, { useState,useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';

import { Loader, Header } from 'components';

import styles from './style';
import '@fontsource/roboto/latin-ext.css';
import { Footer } from '../index';
import { useSettings } from '../../context/settings';

const Layout = ({ classes, children, noBackground }) => {
  const { darkMode } = useSettings();
  const [ showLoader, setShowLoader ] = useState(true)


  useEffect(()=> {
    setTimeout(() => {
      setShowLoader(false)
    }, 400)
  }, [])
  if (showLoader) {
    return <Loader/>
  }
  return (
    <Scrollbars
      autoHide
      autoHideTimeout={1000}
      autoHideDuration={200}
      renderView={props => <div {...props} className={classes.scrollFix} />}
      className={classNames(classes.container, darkMode ? 'dark' : 'light')}>
      <div
        className={classNames(
          classes.pageContainer,
          !noBackground && classes.background
        )}>
        <Header />
        <Fade in mountOnEnter unmountOnExit>
          <main className={classes.content}>{children}</main>
        </Fade>
        <Footer></Footer>
      </div>
    </Scrollbars>
  );
};

export default withStyles(styles)(Layout);
