import React from 'react';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'gatsby';
import { FaClock } from 'react-icons/fa';

import styles from './style';

const BlogPost = ({ classes, article }) => {
  const isBlogsList = !!article.body.childMarkdownRemark.excerpt;
  return (
    <article className={classes.container}>
      {article.heroImage ? (
        <img
          alt={article.title}
          srcSet={article.heroImage.fluid?.srcSet}
          className={classes['article-picture']}
        />
      ) : null}
      <div className={classes.title}>
        {isBlogsList ? (
          <Typography variant='h2' color='textPrimary'>
            <Link
              to={`/blog/${article.slug}`}
              className={classes['blog-list-title']}>
              {article.title}
            </Link>
          </Typography>
        ) : (
          <Typography variant='h1' color='textPrimary'>
            <Link to={`/blog/${article.slug}`} className={classes.title}>
              {article.title}
            </Link>
          </Typography>
        )}
        <Typography color='textSecondary' className={classes.ttr}>
          <FaClock className={classes.clock}></FaClock>
          {`Vreme čitanja: ${article.body.childMarkdownRemark.timeToRead} minuta`}
        </Typography>
      </div>
      <div className={classes.paragraph}>
        <Typography
          variant='body1'
          color='textSecondary'
          dangerouslySetInnerHTML={{
            __html:
              article.body.childMarkdownRemark.excerpt ??
              article.body.childMarkdownRemark.html,
          }}
        />
        {isBlogsList && (
          <Link color='textPrimary' to={`/blog/${article.slug}`}>
            Pročitaj →
          </Link>
        )}
      </div>
    </article>
  );
};
export default withStyles(styles)(BlogPost);
