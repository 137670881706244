import loader from 'images/loader.svg';
import React from 'react';

const Loader = () => {
  return(<div
    id="___loader"
    style={{
      alignItems: "center",
      backgroundColor: "var(--background)",
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 1
    }}>
    <img
      src={loader}
      alt="Loading Skendzic.dev"
      width="50"
      height="50"
    />
  </div>)
}

export default Loader
